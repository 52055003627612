/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import './src/components/layout.css'


// You can delete this file if you're not using it
require("@fontsource/inter/100.css")
require("@fontsource/inter/200.css")
require("@fontsource/inter/300.css")
require("@fontsource/inter/400.css")
require("@fontsource/inter/500.css")
require("@fontsource/inter/600.css")
require("@fontsource/inter/700.css")
require("@fontsource/inter/800.css")
require("@fontsource/mrs-saint-delafield/400.css")
require("@fontsource-variable/playfair-display/")
require("@fontsource/arsenal")

