exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ad-coloracao-pessoal-js": () => import("./../../../src/pages/ad/coloracao-pessoal.js" /* webpackChunkName: "component---src-pages-ad-coloracao-pessoal-js" */),
  "component---src-pages-ad-rwt-js": () => import("./../../../src/pages/ad/rwt.js" /* webpackChunkName: "component---src-pages-ad-rwt-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-consultoria-assessoria-em-compras-js": () => import("./../../../src/pages/consultoria/assessoria-em-compras.js" /* webpackChunkName: "component---src-pages-consultoria-assessoria-em-compras-js" */),
  "component---src-pages-consultoria-closet-detox-js": () => import("./../../../src/pages/consultoria/closet-detox.js" /* webpackChunkName: "component---src-pages-consultoria-closet-detox-js" */),
  "component---src-pages-consultoria-coloracao-pessoal-js": () => import("./../../../src/pages/consultoria/coloracao-pessoal.js" /* webpackChunkName: "component---src-pages-consultoria-coloracao-pessoal-js" */),
  "component---src-pages-consultoria-imagem-360-js": () => import("./../../../src/pages/consultoria/imagem-360.js" /* webpackChunkName: "component---src-pages-consultoria-imagem-360-js" */),
  "component---src-pages-consultoria-js": () => import("./../../../src/pages/consultoria.js" /* webpackChunkName: "component---src-pages-consultoria-js" */),
  "component---src-pages-consultoria-montagem-de-looks-js": () => import("./../../../src/pages/consultoria/montagem-de-looks.js" /* webpackChunkName: "component---src-pages-consultoria-montagem-de-looks-js" */),
  "component---src-pages-consultoria-visagismo-js": () => import("./../../../src/pages/consultoria/visagismo.js" /* webpackChunkName: "component---src-pages-consultoria-visagismo-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-obrigado-js": () => import("./../../../src/pages/obrigado.js" /* webpackChunkName: "component---src-pages-obrigado-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

